import React, { useState, useEffect } from "react";
import {
  withStyles,
  Button,
  Switch,
  IconButton,
  Typography,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Container,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Card,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Image from "material-ui-image";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import { useAppContext } from "../services/authContext";
import DateFnsUtils from "@date-io/date-fns";
import Avatar from "@material-ui/core/Avatar";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import PatientDataService from "../services/PatientDataService";
import DoctorDataService from "../services/DoctorDataService";
import ClinicDataService from "../services/ClinicDataService";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const useStyles = makeStyles((theme) => ({
  selected: {
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

function IntroTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

IntroTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </Container>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function DoctorDetail(props) {
  const { displayMessage, clinicId, displayAlert } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [introTabIndex, setIntroTabIndex] = useState(30);
  const [whEditorOpen, setWhEditorOpen] = useState(false);
  const [configEditing, setConfigEditing] = useState({});
  const [asEditorOpen, setAsEditorOpen] = useState(false);
  const [asEditing, setAsEditing] = useState({});
  const [categoryEditorOpen, setCategoryEditorOpen] = useState(false);
  const [addCategoryId, setAddCategoryId] = useState("");
  const [allowNewPatient, setAllowNewPatient] = useState("1");
  const [surveyEditorOpen, setSurveyEditorOpen] = useState(false);
  const [surveyCategoryId, setSurveyCategoryId] = useState("");
  const [surveyTreatmentTypeId, setSurveyTreatmentTypeId] = useState();
  const [selectedSurveyId, setSelectSurveyId] = useState("");
  const [surveyAllowNewPatient, setSurveyAllowNewPatient] = useState(false);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [categoryChangeSettingOpen, setCategoryChangeSettingOpen] = useState(
    false
  );
  const [categorySetting, setCategorySetting] = useState({});

  function openSurveyEditor() {
    setSurveyCategoryId("");
    setSurveyTreatmentTypeId(null);
    setSelectSurveyId("");
    setSurveyEditorOpen(true);
  }

  function closeSurveyEditor() {
    setSurveyEditorOpen(false);
  }

  function closeChangeCategorySetting() {
    setCategoryChangeSettingOpen(false);
  }

  function changeSurvey(event) {
    console.log(event.target.value);
    setSelectSurveyId(event.target.value);
  }

  function changeSurveyCategory(event) {
    ClinicDataService.findCategoryTypes(event.target.value).then(
      (response) => {
        let tmpTypes = [];
        response.data.forEach((rawType) => {
          let description = JSON.parse(rawType.name);
          tmpTypes.push({
            id: rawType.id + "",
            name: description.en,
          });
        });
        setTreatmentTypes([...tmpTypes]);
      },
      (error) => console.log(error)
    );
    setSurveyCategoryId(event.target.value);
  }

  function changeSurveyTreatment(event) {
    setSurveyTreatmentTypeId(event.target.value);
  }

  function saveSurvey2Doctor() {
    let errorMessage = [];
    if ("" === surveyCategoryId) {
      errorMessage.push("category");
    }
    if (null == surveyTreatmentTypeId) {
      errorMessage.push("treatment type");
    }
    if ("" === selectedSurveyId) {
      errorMessage.push("survey");
    }

    if (errorMessage.length != 0) {
      displayAlert(errorMessage.join(", ") + " cannot be empty");
    } else {
      DoctorDataService.saveSurvey(props.doctor.id, {
        categoryId: surveyCategoryId,
        treatmentTypeId: surveyTreatmentTypeId,
        surveyId: selectedSurveyId,
        forNewPatient: surveyAllowNewPatient,
      }).then(
        (response) => {
          props.populateDoctorSurveys(props.doctor.id);
          displayMessage(
            "The survey has been successfully added to the doctor"
          );
          setSurveyEditorOpen(false);
        },
        (error) => console.log(error)
      );
    }
  }

  function changeSurveyAllowNewPatient(event) {
    setSurveyAllowNewPatient(event.target.checked);
  }

  const changeAllowNewPatient = (event) => {
    setAllowNewPatient(event.target.value);
  };

  function saveCategory2Docoto() {
    if (!addCategoryId || addCategoryId.length === 0) {
      alert("Please select a category.");
      return;
    }
    DoctorDataService.linkCategory2Doctor(props.doctor.id, {
      id: null,
      clinicId: clinicId,
      categoryId: addCategoryId,
      allowNewPatient: parseInt(allowNewPatient),
      status: 1,
    }).then(
      (response) => {
        props.populateDoctorCategories(props.doctor.id);
        setAddCategoryId(null);
      },
      (error) => console.log(error)
    );
    setCategoryEditorOpen(false);
  }

  const deleteCategory = (categoryId) => (event) => {
    if (
      window.confirm(
        "Do you really want to remove this category from the doctor?"
      )
    ) {
      DoctorDataService.deleteDoctorCategory(props.doctor.id, categoryId).then(
        (response) => {
          props.populateDoctorCategories(props.doctor.id);
          props.populateDoctorTreatment(props.doctor.id);
          displayMessage("The category has been removed from the doctor");
        },
        (error) => console.log(error)
      );
    }
  };

  function addCategory() {
    setCategoryEditorOpen(true);
  }

  const changeCategorySetting = (categoryId) => (event) => {
    console.log(categoryId);
    setCategorySetting({
      ...props.categories.find(
        (category) => category.categoryId === parseInt(categoryId)
      ),
    });
    console.log(categorySetting);
    setCategoryChangeSettingOpen(true);
    console.log(categoryChangeSettingOpen);
  };

  const changeSettingAllowNewPatient = (event) => {
    setCategorySetting({
      ...categorySetting,
      allowNewPatientId: event.target.value,
    });
  };

  function updateCategorySetting() {
    DoctorDataService.updateCategorySetting(props.doctor.id, {
      id: null,
      clinicId: clinicId,
      categoryId: categorySetting.categoryId,
      allowNewPatient: categorySetting.allowNewPatientId,
      status: 1,
    }).then(
      (response) => {
        props.populateDoctorCategories(props.doctor.id);
        displayMessage("The category has been updated successfully");
        setCategoryChangeSettingOpen(false);
      },
      (error) => console.log(error)
    );
  }

  function validCategoryLeaf(categoryId) {
    const category = props.fullCategories.filter(
      (item) => item.id === categoryId
    );
    return category[0].isLeaf === "1" ? true : false;
  }

  function closeFullCategoryEditor() {
    setCategoryEditorOpen(false);
  }

  const changeFullCategory = (event) => {
    setAddCategoryId(event.target.value);
  };

  const addWh = (event) => {
    setConfigEditing({
      startDate: new Date(),
      endDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      weekday: "",
    });
    setWhEditorOpen(true);
  };

  const deleteWh = (availTimeId) => (event) => {
    if (window.confirm("Do you really want to delete this working hours?")) {
      DoctorDataService.deleteAvailableTime(props.doctor.id, availTimeId).then(
        (response) => {
          props.populateDoctorAvailTime(props.doctor.id);
          displayMessage("The working hours has been deleted from the doctor");
        },
        (error) => console.log(error)
      );
    }
  };

  const openWhEditorOpen = (config) => (event) => {
    setConfigEditing({
      ...config,
      startDate: new Date(config.startDate + "T00:00:00"),
      endDate: new Date(config.endDate + "T00:00:00"),
      startTime: new Date(config.endDate + "T" + config.startTime),
      endTime: new Date(config.endDate + "T" + config.endTime),
    });
    setWhEditorOpen(true);
  };

  const changeCategory = (event) => {
    setConfigEditing({ ...configEditing, categoryId: event.target.value });
    ClinicDataService.findCategoryTypes(event.target.value).then(
      (response) => {
        let tmpTypes = [];
        response.data.forEach((rawType) => {
          let description = JSON.parse(rawType.name);
          tmpTypes.push({
            id: rawType.id + "",
            name: description.en,
          });
        });
        setCategoryTypes([...tmpTypes]);
      },
      (error) => console.log(error)
    );
  };

  const changeTreatment = (event) => {
    setConfigEditing({ ...configEditing, treatmentTypeId: event.target.value });
  };

  const handleDateAndTimeChange = (keyWord) => (date) => {
    setConfigEditing({ ...configEditing, [keyWord]: date });
  };

  const changeWorkDay = (index) => (event) => {
    const days = configEditing.weekday.split(",");
    if (configEditing.weekday.includes(index)) {
      const position = days.indexOf(index + "");
      if (position > -1) {
        days.splice(position, 1);
      }
    } else {
      const position = days.findIndex((day) => index < day);
      if (position > -1) {
        days.splice(position, 0, index);
      } else {
        days.push(index);
      }
    }
    setConfigEditing({
      ...configEditing,
      weekday: days.filter((e) => e !== "").join(","),
    });
  };

  function saveAvailTime() {
    const startDateStr =
      configEditing.startDate.getFullYear() +
      "-" +
      (configEditing.startDate.getMonth() + 1) +
      "-" +
      configEditing.startDate.getDate();
    const endDateStr =
      configEditing.endDate.getFullYear() +
      "-" +
      (configEditing.endDate.getMonth() + 1) +
      "-" +
      configEditing.endDate.getDate();

    if (
      !props.doctor.id ||
      !configEditing.categoryId ||
      !configEditing.treatmentTypeId ||
      !configEditing.weekday ||
      !startDateStr ||
      !endDateStr
    ) {
      displayAlert("All information is required.");
      return;
    }

    if (configEditing.id) {
      DoctorDataService.saveAvailableTime(props.doctor.id, {
        id: configEditing.id,
        doctorId: props.doctor.id,
        categoryId: configEditing.categoryId,
        treatmentTypeId: configEditing.treatmentTypeId,
        weekday: configEditing.weekday,
        startDate: startDateStr,
        endDate: endDateStr,
        startTime:
          configEditing.startTime.getHours() +
          ":" +
          configEditing.startTime.getMinutes() +
          ":00",
        endTime:
          configEditing.endTime.getHours() +
          ":" +
          configEditing.endTime.getMinutes() +
          ":00",
      }).then(
        (response) => props.populateDoctorAvailTime(props.doctor.id),
        (error) => console.log(error)
      );
    } else {
      DoctorDataService.createAvailableTime(props.doctor.id, {
        doctorId: props.doctor.id,
        categoryId: configEditing.categoryId,
        treatmentTypeId: configEditing.treatmentTypeId,
        weekday: configEditing.weekday,
        startDate: startDateStr,
        endDate: endDateStr,
        startTime:
          configEditing.startTime.getHours() +
          ":" +
          configEditing.startTime.getMinutes(),
        endTime:
          configEditing.endTime.getHours() +
          ":" +
          configEditing.endTime.getMinutes(),
      }).then(
        (response) => props.populateDoctorAvailTime(props.doctor.id),
        (error) => console.log(error)
      );
    }
    closeWhDialog();
  }

  function closeWhDialog() {
    setWhEditorOpen(false);
  }

  function addAs() {
    setAsEditing({});
    setAsEditorOpen(true);
  }

  const openAsEditor = (config) => (event) => {
    setAsEditing({ ...config });
    setAsEditorOpen(true);
  };

  function closeAsDialog() {
    setAsEditorOpen(false);
  }

  function saveAppSetting() {
    DoctorDataService.saveDoctorTreatmentConfig(props.doctor.id, {
      ...asEditing,
      clinicId: clinicId,
    }).then(
      (response) => props.populateDoctorTreatment(clinicId, props.doctor.id),
      (error) => console.log(error)
    );
    setAsEditorOpen(false);
  }

  function changeAsCategory(event) {
    setAsEditing({
      ...asEditing,
      categoryId: event.target.value,
    });
    ClinicDataService.findCategoryTypes(event.target.value).then(
      (response) => {
        let tmpTypes = [];
        response.data.forEach((rawType) => {
          let description = JSON.parse(rawType.name);
          tmpTypes.push({
            id: rawType.id + "",
            name: description.en,
          });
        });
        setTreatmentTypes([...tmpTypes]);
      },
      (error) => console.log(error)
    );
  }

  function changeAsTreatment(event) {
    setAsEditing({
      ...asEditing,
      treatmentTypeId: parseInt(event.target.value),
    });
  }

  function changeAsSlot(event) {
    setAsEditing({
      ...asEditing,
      timeSlotLength: event.target.value,
    });
  }

  function changeAsCapacity(event) {
    setAsEditing({
      ...asEditing,
      timeSlotCapacity: event.target.value,
    });
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function handleIntroTabIndex(event, newValue) {
    setIntroTabIndex(newValue);
  }

  const unlinkSurvey = (surveyId) => (event) => {
    if (
      window.confirm(
        "Do you really want to remove this survey from the doctor?"
      )
    ) {
      DoctorDataService.removeDoctorSurvey(surveyId).then(
        (response) => {
          props.populateDoctorSurveys(props.doctor.id);
          displayMessage("The survey has been removed from the doctor");
        },
        (error) => console.log(error)
      );
    }
  };

  const unassginPatient = (patientId, doctorId) => (event) => {
    if (
      window.confirm(
        "Do you really want to unassign this patient from the doctor?"
      )
    ) {
      PatientDataService.unassignPatientFromDoc(patientId, doctorId).then(
        (response) => {
          props.populatePatients(doctorId);
          displayMessage("The patient has been unassigned from the doctor");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      className={classes.selected}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography>
        <IconButton color="primary" onClick={props.showDoctorList}>
          <ArrowBackIosIcon fontSize="large" />
          Back
        </IconButton>
      </Typography>
      <Container>
        <AppBar position="static" color="default" style={{ marginTop: 10 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Information" {...a11yProps(0)} />
            <Tab label="Patients" {...a11yProps(1)} />
            <Tab label="Appointment Setting" {...a11yProps(2)} />
            <Tab label="Working hours" {...a11yProps(3)} />
            <Tab label="Survey" {...a11yProps(4)} />
            <Tab label="Appointments" {...a11yProps(5)} />
          </Tabs>
        </AppBar>
        <TabPanel value={currentTabIndex} index={0}>
          <Grid
            item
            md={2}
            style={{
              height: "100%",
              display: props.doctor.photoUrl ? "block" : "none",
            }}
          >
            <Card
              style={{
                marginTop: "16px",
                marginBottom: "16px",
                height: "100%",
              }}
            >
              <Image src={props.doctor.photoUrl} />
            </Card>
          </Grid>
          <Typography variant="h6">General Information</Typography>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">First Name</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.editDoctor.firstName}
                    onChange={props.changeDoctorInfo("firstName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Middle Name</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.editDoctor.middleName}
                    onChange={props.changeDoctorInfo("middleName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Family Name</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.editDoctor.lastName}
                    onChange={props.changeDoctorInfo("lastName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Date of Birth</Typography>
                </TableCell>
                <TableCell>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={props.docDob}
                      onChange={props.changeDocDob}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Gender</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.doctor.gender === "M" ? "Male" : "Female"}
                    onChange={props.changeDoctorInfo("gender")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Medical Card Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.doctor.mediCardNumber}
                    onChange={props.changeDoctorInfo("mediCardNumber")}
                    disabled
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Expiration</Typography>
                </TableCell>
                <TableCell>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={props.docCardExp}
                      onChange={props.changeDocCardExp}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Languages spoken</Typography>
                </TableCell>
                <TableCell>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.languages.fr}
                            onChange={props.changeLanguge}
                            name="fr"
                          />
                        }
                        label="French"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.languages.en}
                            onChange={props.changeLanguge}
                            name="en"
                          />
                        }
                        label="English"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.languages.zh}
                            onChange={props.changeLanguge}
                            name="zh"
                          />
                        }
                        label="Mandarin"
                      />
                    </FormGroup>
                  </FormControl>
                  <TextField
                    value={props.doctor.lang}
                    onChange={props.changeDoctorInfo("lang")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Category</Typography>
                </TableCell>
                <TableCell>
                  {props.categories.map((category) => (
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: 18 }}
                    >
                      <TextField
                        value={category.name}
                        variant="outlined"
                        style={{ minWidth: 400 }}
                      />
                      <TextField
                        value={category.allowNewPatient}
                        style={{ marginLeft: 18, marginRight: 18 }}
                      />
                      <IconButton
                        onClick={changeCategorySetting(category.categoryId)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={deleteCategory(category.categoryId)}>
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  ))}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={addCategory}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6">Contact Information</Typography>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: 300 }}>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Contact Phone
                  </Typography>
                </TableCell>
                <TableCell>
                  {props.editDoctor.contactPhoneType === "CELL" ? (
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={2}>
                          <Typography
                            variant="subtitle1"
                            className="content-subtitle"
                          >
                            Mobile Phone
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            value={props.editDoctor.contactPhone}
                            onChange={props.changeDoctorInfo("contactPhone")}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  props.editDoctor.contactPhoneType === "CELL"
                                }
                                onChange={props.changePhoneType("LAND")}
                                name="mobilephone"
                                color="primary"
                              />
                            }
                            label="Mobile"
                          />
                        </Grid>
                        <Grid item md={6}></Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={2}>
                          <Typography
                            variant="subtitle1"
                            className="content-subtitle"
                          >
                            Landline
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            value={props.editDoctor.contactPhone}
                            onChange={props.changeDoctorInfo("contactPhone")}
                            style={{ marginRight: 30 }}
                          />
                        </Grid>
                        <Grid item md={1}>
                          <Typography
                            variant="subtitle11"
                            className="content-subtitle"
                          >
                            Ext
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            value={props.editDoctor.contactPhoneExt}
                            onChange={props.changeDoctorInfo("contactPhoneExt")}
                            style={{ marginRight: 30 }}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  props.editDoctor.contactPhoneType === "CELL"
                                }
                                onChange={props.changePhoneType("CELL")}
                                name="Mobile"
                                color="primary"
                              />
                            }
                            label="Mobile"
                          />
                        </Grid>
                        <Grid item md={3}></Grid>
                      </Grid>
                    </Grid>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Email</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.editDoctor.email}
                    onChange={props.changeDoctorInfo("email")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Address</Typography>
                </TableCell>
                <TableCell>
                  <Grid container spacing={3}>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          Apartment
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.apartment}
                          onChange={props.changeDoctorInfo("apartment")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          Street Number
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.streetNumber}
                          onChange={props.changeDoctorInfo("streetNumber")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth>
                        <FormLabel className={classes.inputTitle}>
                          Street Name
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          className={classes.textField}
                          value={props.editDoctor.streetName}
                          onChange={props.changeDoctorInfo("streetName")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          City
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.city}
                          onChange={props.changeDoctorInfo("city")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          Province
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.provinceId}
                          onChange={props.changeDoctorInfo("provinceId")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          PostalCode
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.postcode}
                          onChange={props.changeDoctorInfo("postcode")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl>
                        <FormLabel className={classes.inputTitle}>
                          Country
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          value={props.editDoctor.country}
                          onChange={props.changeDoctorInfo("country")}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6">Introduction</Typography>
          <Divider />
          <Typography variant="subtitle1">English</Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={8}
            variant="outlined"
            fullWidth
            value={props.editDoctor.enIntro}
            onChange={props.changeDoctorInfo("enIntro")}
          />
          <Typography variant="subtitle1">French</Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={8}
            variant="outlined"
            fullWidth
            value={props.editDoctor.frIntro}
            onChange={props.changeDoctorInfo("frIntro")}
          />
          <Table>
            <TableBody>
              <TableRow>
                {/* <TableCell style={{ textAlign: "right" }}>
                <Button variant="contained">Reset</Button>
              </TableCell> */}
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.updateDoctor}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={1}
          className="message-container"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Medical card</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.patients.map((patient) => (
                <TableRow key={patient.id}>
                  <TableCell style={{ minWidth: 50 }}>
                    <Avatar src={patient.photoUrl} />
                  </TableCell>
                  <TableCell>
                    {patient.middleName == null
                      ? patient.firstName + " " + patient.lastName
                      : patient.firstName +
                        " " +
                        patient.middleName +
                        " " +
                        patient.lastName}
                  </TableCell>
                  <TableCell>{patient.mediCardNumber}</TableCell>
                  <TableCell>{patient.email}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={unassginPatient(patient.id, props.doctor.id)}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={2}
          className="message-container"
        >
          {props.treatmentConfig.map((treatment) => (
            <Accordion
              square
              key={treatment[1].id}
              className="patient-msg-item"
            >
              <AccordionSummary
                className="expand-icon"
                expandIcon={<ExpandMoreIcon />}
              >
                {/* <Typography>
                  {JSON.parse(treatment[1][0].categoryDescription).en}
                </Typography> */}
                <div className="item-title">
                  <div className="title">
                    <div className="content">
                      {JSON.parse(treatment[1][0].categoryDescription).en}
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="item-content-label">Type</TableCell>
                      <TableCell className="item-content-label">
                        Time Slot (min)
                      </TableCell>
                      <TableCell className="item-content-label">
                        Capacity (# of patient per time)
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {treatment[1].map((config) => (
                      <TableRow key={config.id}>
                        <TableCell>
                          {JSON.parse(config.treatmentType).en}
                        </TableCell>
                        <TableCell>{config.timeSlotLength}</TableCell>
                        <TableCell>{config.timeSlotCapacity}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={openAsEditor(config)}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button color="primary" variant="contained" onClick={addAs}>
            Add
          </Button>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={3}
          className="message-container"
        >
          {props.availTimes.map((availTime) => (
            <Accordion square key={availTime.id} className="patient-msg-item">
              <AccordionSummary
                className="expand-icon"
                expandIcon={<ExpandMoreIcon />}
              >
                {/* <Typography>
                  {JSON.parse(availTime[1][0].categoryDescription).en}
                </Typography> */}
                <div className="item-title">
                  <div className="title title-from">
                    <div className="content">
                      {JSON.parse(availTime[1][0].categoryDescription).en}
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Grid container className="patient-working-hour">
                  {availTime[1].map((config) => (
                    <React.Fragment>
                      <div className="line-area">
                        <Grid item className="working-hour-left">
                          <Typography variant="h6">
                            {JSON.parse(config.treatmentType).en}
                          </Typography>
                        </Grid>
                        <Grid item className="working-hour-right">
                          <Typography className="button-group">
                            {weekDays.map((day, index) => (
                              <Button
                                variant="contained"
                                color="primary"
                                className="date-btn"
                                disabled={!config.weekday.includes(index)}
                              >
                                {day}
                              </Button>
                            ))}
                          </Typography>
                          <Table className="date-table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="date-label">
                                  <Typography>Start Date</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{config.startDate}</Typography>
                                </TableCell>
                                <TableCell className="date-label">
                                  <Typography>End Date</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{config.endDate}</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="date-label">
                                  <Typography>Start Time</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{config.startTime}</Typography>
                                </TableCell>
                                <TableCell className="date-label">
                                  <Typography>End Time</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{config.endTime}</Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={openWhEditorOpen(config)}
                            style={{ marginRight: 30 }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            onClick={deleteWh(config.id)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </div>
                    </React.Fragment>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button color="primary" variant="contained" onClick={addWh}>
            Add
          </Button>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={4}
          className="message-container"
        >
          {props.surveys.map((categorySurvey) => (
            <Accordion
              square
              key={categorySurvey.id}
              className="patient-msg-item"
            >
              <AccordionSummary
                className="expand-icon"
                expandIcon={<ExpandMoreIcon />}
              >
                {/* <Typography>
                  {JSON.parse(availTime[1][0].categoryDescription).en}
                </Typography> */}
                <div className="item-title">
                  <div className="title title-from">
                    <div className="content">
                      {JSON.parse(categorySurvey[1][0].desc).en}
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Grid container className="patient-working-hour">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categorySurvey[1].map((survey) => (
                        <TableRow>
                          <TableCell className="date-label">
                            {JSON.parse(survey.type).en}
                          </TableCell>
                          <TableCell className="date-label">
                            {survey.title}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={unlinkSurvey(survey.id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button
            color="primary"
            variant="contained"
            onClick={openSurveyEditor}
          >
            Add
          </Button>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={5}
          className="message-container"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Doctor Name</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.apps.map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>
                    {appointment.appointmentDate} {appointment.startTime}
                  </TableCell>
                  <TableCell>{appointment.doctorName}</TableCell>
                  <TableCell>{appointment.patientName}</TableCell>
                  <TableCell>{appointment.clinicName}</TableCell>
                  <TableCell>
                    {appointment.apartment} {appointment.streetNumber}{" "}
                    {appointment.streetName}, {appointment.city}
                  </TableCell>
                  <TableCell>
                    {JSON.parse(appointment.categoryDescription).en}
                  </TableCell>
                  <TableCell>
                    {JSON.parse(appointment.treatmentType).en}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>

        <Dialog open={whEditorOpen} onClose={closeWhDialog} maxWidth="md">
          <DialogTitle>Edit Working Hours</DialogTitle>
          <DialogContent>
            {configEditing.id ? (
              ""
            ) : (
              <React.Fragment>
                <FormControl style={{ minWidth: "50%" }}>
                  <Typography variant="subtitle2">Category</Typography>
                  <Select
                    onChange={changeCategory}
                    value={configEditing.categoryId}
                  >
                    {props.categories.map((category) => (
                      <MenuItem value={category.categoryId}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup
                    row
                    value={configEditing.treatmentType}
                    onChange={changeTreatment}
                  >
                    {categoryTypes.map((type) => (
                      <FormControlLabel
                        value={type.id}
                        control={<Radio color="primary" />}
                        label={type.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </React.Fragment>
            )}
            <Typography className="button-group" align="center">
              {weekDays.map((day, index) => (
                <Button
                  variant="contained"
                  color="primary"
                  className="date-btn"
                  color={
                    configEditing.weekday
                      ? configEditing.weekday.includes(index)
                        ? "primary"
                        : "default"
                      : "default"
                  }
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={changeWorkDay(index)}
                >
                  {day}
                </Button>
              ))}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Table className="date-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="date-label">
                      <Typography>Start Date</Typography>
                    </TableCell>
                    <TableCell className="date-label">
                      <KeyboardDatePicker
                        margin="normal"
                        id="startDate-picker"
                        label="Date picker dialog"
                        format="yyyy-MM-dd"
                        value={configEditing.startDate}
                        onChange={handleDateAndTimeChange("startDate")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </TableCell>
                    <TableCell className="date-label">
                      <Typography>End Date</Typography>
                    </TableCell>
                    <TableCell className="date-label">
                      <KeyboardDatePicker
                        margin="normal"
                        id="endDate-picker"
                        label="Date picker dialog"
                        format="yyyy-MM-dd"
                        value={configEditing.endDate}
                        onChange={handleDateAndTimeChange("endDate")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="date-label">
                      <Typography>Start Time</Typography>
                    </TableCell>
                    <TableCell className="date-label">
                      <KeyboardTimePicker
                        margin="normal"
                        id="startTime-picker"
                        label="Time picker"
                        value={configEditing.startTime}
                        onChange={handleDateAndTimeChange("startTime")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </TableCell>
                    <TableCell className="date-label">
                      <Typography>End Time</Typography>
                    </TableCell>
                    <TableCell className="date-label">
                      <KeyboardTimePicker
                        margin="normal"
                        id="endTime-picker"
                        label="Time picker"
                        value={configEditing.endTime}
                        onChange={handleDateAndTimeChange("endTime")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={saveAvailTime}>
              Save
            </Button>
            <Button variant="outlined" onClick={closeWhDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={asEditorOpen} onClose={closeAsDialog} maxWidth="md">
          <DialogTitle>Edit Appointment Setting</DialogTitle>
          <DialogContent>
            {props.categories.length === 0 ? (
              <Typography variant="h5">
                There is no category available
              </Typography>
            ) : (
              ""
            )}
            <FormControl style={{ minWidth: "50%" }}>
              <Typography variant="subtitle2">Category</Typography>
              <Select
                onChange={changeAsCategory}
                value={asEditing.categoryId}
                disabled={asEditing.id != null}
              >
                {props.categories.map((category) => (
                  <MenuItem value={category.categoryId}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "24px" }}>
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup
                row
                onChange={changeAsTreatment}
                value={asEditing.treatmentTypeId + ""}
              >
                {treatmentTypes.map((type) => (
                  <FormControlLabel
                    disabled={asEditing.id != null}
                    value={type.id}
                    control={<Radio color="primary" />}
                    label={type.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl style={{ margin: "30px 30px 30px 0" }}>
              <FormLabel>Time Slot (min)</FormLabel>
              <TextField
                value={asEditing.timeSlotLength}
                onChange={changeAsSlot}
              />
            </FormControl>
            <FormControl style={{ margin: 30 }}>
              <FormLabel>Capacity (# of patient per time)</FormLabel>
              <TextField
                value={asEditing.timeSlotCapacity}
                onChange={changeAsCapacity}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={saveAppSetting}
              disabled={
                props.categories.length === 0 ||
                !asEditing.categoryId ||
                !asEditing.treatmentTypeId ||
                !asEditing.timeSlotLength ||
                !asEditing.timeSlotCapacity
              }
            >
              Save
            </Button>
            <Button variant="outlined" onClick={closeAsDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={categoryChangeSettingOpen}
          onClose={closeChangeCategorySetting}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Change Category Setting</DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Category: {categorySetting.name}
            </Typography>
            <RadioGroup
              name="allow"
              value={categorySetting.allowNewPatientId}
              onChange={changeSettingAllowNewPatient}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Allow new patient"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Allow new patient if urgent case"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Don't accept new patient"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={updateCategorySetting}
            >
              Save
            </Button>
            <Button variant="contained" onClick={closeChangeCategorySetting}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={categoryEditorOpen}
          onClose={closeFullCategoryEditor}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Add category to doctor</DialogTitle>
          <DialogContent>
            <FormControl
              style={{
                border: "1px solid #3f51b514",
                borderRadius: "8px",
                minWidth: "60%",
                marginRight: 30,
                width: "calc(100% - 12px)",
                overflowY: "auto",
                height: "250px",
              }}
            >
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={["root"]}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={(event, nodeId) => {
                  // remove current value
                  setAddCategoryId(null);
                  // only leaf node can be current value
                  if (validCategoryLeaf(nodeId)) {
                    setAddCategoryId(nodeId);
                  }
                }}
              >
                {renderTree(props.categorieViewDataList)}
              </TreeView>
            </FormControl>
            <RadioGroup
              name="allow"
              value={allowNewPatient}
              onChange={changeAllowNewPatient}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Allow new patient"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Allow new patient if urgent case"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Don't accept new patient"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={saveCategory2Docoto}
            >
              Save
            </Button>
            <Button variant="contained" onClick={closeFullCategoryEditor}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={surveyEditorOpen}
          onClose={closeSurveyEditor}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Assign survey to appointment</DialogTitle>
          <DialogContent>
            <FormControl style={{ minWidth: "60%", marginRight: 30 }}>
              <Typography variant="subtitle2">
                <b style={{ color: "red" }}>*</b> Category
              </Typography>
              <Select onChange={changeSurveyCategory} value={surveyCategoryId}>
                {props.categories.map((category) => (
                  <MenuItem value={category.categoryId}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel component="legend">
                <b style={{ color: "red" }}>*</b> Type
              </FormLabel>
              <RadioGroup
                row
                value={surveyTreatmentTypeId}
                onChange={changeSurveyTreatment}
              >
                {treatmentTypes.map((type) => (
                  <FormControlLabel
                    value={type.id}
                    control={<Radio color="primary" />}
                    label={type.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel component="legend">
                <Typography variant="subtitle2">
                  <b style={{ color: "red" }}>*</b> Survey
                </Typography>
                <Select
                  onChange={changeSurvey}
                  value={selectedSurveyId}
                  fullWidth
                >
                  {props.clinicSurveys.map((survey) => (
                    <MenuItem value={survey.id}>
                      {survey.id + " - " + survey.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormLabel>
            </FormControl>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={surveyAllowNewPatient}
                  onChange={changeSurveyAllowNewPatient}
                  color="primary"
                />
              }
              label="Allow new patient"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={saveSurvey2Doctor}
            >
              Save
            </Button>
            <Button variant="contained" onClick={closeSurveyEditor}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment>
  );
}

export default DoctorDetail;
